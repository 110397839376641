.testimonial-body {
    max-height: 200px;
    overflow-x: auto;
    overflow-y: scroll;
}


::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
    height: 10px; /* Height of the scrollbar (for horizontal scroll) */
}

::-webkit-scrollbar-track {
    background: #f0f0f0; /* Background color of the scrollbar track */
    border-radius: 5px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
    background: #c0c0c0; /* Scrollbar thumb color */
    border-radius: 5px; /* Rounded corners */
    border: 2px solid #f0f0f0; /* Space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; /* Hover effect for the scrollbar thumb */
}


