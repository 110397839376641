/* Font Definitions */
@font-face {
    font-family: Wingdings;
    panose-1: 5 0 0 0 0 0 0 0 0 0;
}

@font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
}

@font-face {
    font-family: Aptos;
}

/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
    margin: 0cm;
    font-size: 12.0pt;
    font-family: "Aptos", sans-serif;
}

p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    font-size: 12.0pt;
    font-family: "Aptos", sans-serif;
}

p.MsoListParagraphCxSpFirst,
li.MsoListParagraphCxSpFirst,
div.MsoListParagraphCxSpFirst {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    font-size: 12.0pt;
    font-family: "Aptos", sans-serif;
}

p.MsoListParagraphCxSpMiddle,
li.MsoListParagraphCxSpMiddle,
div.MsoListParagraphCxSpMiddle {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    font-size: 12.0pt;
    font-family: "Aptos", sans-serif;
}

p.MsoListParagraphCxSpLast,
li.MsoListParagraphCxSpLast,
div.MsoListParagraphCxSpLast {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 36.0pt;
    font-size: 12.0pt;
    font-family: "Aptos", sans-serif;
}

.MsoChpDefault {
    font-size: 12.0pt;
    font-family: "Aptos", sans-serif;
}

@page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 72.0pt 72.0pt 72.0pt 72.0pt;
}

div.WordSection1 {
    page: WordSection1;
}

/* List Definitions */
ol {
    margin-bottom: 0cm;
}

ul {
    margin-bottom: 0cm;
}

td {
    padding: 10px
}