body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

p,
a,
li,
td {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

/* Custom CSS to trigger dropdown on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}
/* Optional: Remove delay when hovering out */
.dropdown-menu {
  margin-top: 0;
  transition: all 0.3s ease-in-out;
}

.dropdown-menu a {
  transition: all 0.3s ease-in-out;
}

.dropdown-menu a:hover {
  background-color: #d1b67f;
  color: white;
}

.dropdown-menu a:focus {
  background-color: #d1b67f;
}

.fs-6 {
  font-size: 0.375rem; /* 6px */
}

.fs-7 {
  font-size: 0.4375rem; /* 7px */
}

.fs-8 {
  font-size: 0.5rem; /* 8px */
}

.fs-9 {
  font-size: 0.5625rem; /* 9px */
}

.fs-10 {
  font-size: 0.625rem; /* 10px */
}

.fs-11 {
  font-size: 0.6875rem; /* 11px */
}

.fs-12 {
  font-size: 0.75rem; /* 12px */
}

.fs-13 {
  font-size: 0.8125rem; /* 13px */
}

.fs-14 {
  font-size: 0.875rem; /* 14px */
}

.fs-15 {
  font-size: 0.9375rem; /* 15px */
}

.fs-16 {
  font-size: 1rem; /* 16px */
}

.fs-17 {
  font-size: 1.0625rem; /* 17px */
}

.fs-18 {
  font-size: 1.125rem; /* 18px */
}

.fs-19 {
  font-size: 1.1875rem; /* 19px */
}

.fs-20 {
  font-size: 1.25rem; /* 20px */
}

.fs-21 {
  font-size: 1.3125rem; /* 21px */
}

.fs-22 {
  font-size: 1.375rem; /* 22px */
}

.fs-23 {
  font-size: 1.4375rem; /* 23px */
}

.fs-24 {
  font-size: 1.5rem; /* 24px */
}

.fs-26 {
  font-size: 1.625rem; /* 26px */
}

.fs-28 {
  font-size: 1.75rem; /* 28px */
}

.fs-30 {
  font-size: 1.875rem; /* 30px */
}

.fs-32 {
  font-size: 2rem; /* 32px */
}

.fs-34 {
  font-size: 2.125rem; /* 34px */
}

.fs-36 {
  font-size: 2.25rem; /* 36px */
}

.fs-38 {
  font-size: 2.375rem; /* 38px */
}

.fs-40 {
  font-size: 2.5rem; /* 40px */
}

.fs-44 {
  font-size: 2.75rem; /* 44px */
}

.fs-48 {
  font-size: 3rem; /* 48px */
}

.fs-50 {
  font-size: 3.125rem; /* 50px */
}

.fs-60 {
  font-size: 3.75rem; /* 60px */
}

.fs-70 {
  font-size: 4.375rem; /* 70px */
}

.fs-80 {
  font-size: 5rem; /* 80px */
}

.dropdown-toggle::after {
  display: none; /* Hide the arrow */
}

.btn-light {
  transition: all 0.8s ease;
}

.btn-dark {
  transition: all 0.8s ease;
}

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #e3e7eb !important;
  }

  .border-sm-right {
    border-right: 1px solid #e3e7eb !important;
  }

  .border-sm-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-sm-left {
    border-left: 1px solid #e3e7eb !important;
  }

  .border-sm-top-0 {
    border-top: 0 !important;
  }

  .border-sm-right-0 {
    border-right: 0 !important;
  }

  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-sm-left-0 {
    border-left: 0 !important;
  }

  .border-sm-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-sm-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-sm {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-sm-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #e3e7eb !important;
  }

  .border-md-right {
    border-right: 1px solid #e3e7eb !important;
  }

  .border-md-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-md-left {
    border-left: 1px solid #e3e7eb !important;
  }

  .border-md-top-0 {
    border-top: 0 !important;
  }

  .border-md-right-0 {
    border-right: 0 !important;
  }

  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-md-left-0 {
    border-left: 0 !important;
  }

  .border-md-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-md-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-md {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-md-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #e3e7eb !important;
  }

  .border-lg-right {
    border-right: 1px solid #e3e7eb !important;
  }

  .border-lg-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-lg-left {
    border-left: 1px solid #e3e7eb !important;
  }

  .border-lg-top-0 {
    border-top: 0 !important;
  }

  .border-lg-right-0 {
    border-right: 0 !important;
  }

  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-lg-left-0 {
    border-left: 0 !important;
  }

  .border-lg-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-lg-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-lg {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-lg-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-xl-top {
    border-top: 1px solid #e3e7eb !important;
  }

  .border-xl-right {
    border-right: 1px solid #e3e7eb !important;
  }

  .border-xl-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-xl-left {
    border-left: 1px solid #e3e7eb !important;
  }

  .border-xl-top-0 {
    border-top: 0 !important;
  }

  .border-xl-right-0 {
    border-right: 0 !important;
  }

  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }

  .border-xl-left-0 {
    border-left: 0 !important;
  }

  .border-xl-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-xl-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }

  .border-xl {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }

  .border-xl-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.navbar {
  background-color: transparent;
  border-bottom: 1px solid rgb(196, 196, 196);
}

.bg-theme {
  background-color: #27333d;
}

.bg-golden {
  background-color: #d1b67f;
}

.text-theme {
  color: #495c6a;
}

.text-golden {
  color: #d1b67f;
}

.btn-theme-nav {
  background-color: #27333d;
  color: white;
  width: 200px;
  height: 50px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.btn-theme-nav:hover {
  background-color: #27333d;
  color: #d1b67f;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 260px;
  height: 60px;
}

.btn-golden-nav {
  background-color: #d1b67f;
  width: 200px;
  height: 50px;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  transition: all 0.5s ease;
}

.btn-golden-nav:hover {
  background-color: #d1b67f;
  color: black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 260px;
  height: 60px;
}

.btn-theme {
  background-color: #27333d;
  color: white;
  width: 250px;
  height: 50px;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.btn-theme:hover {
  background-color: #27333d;
  color: white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 260px;
  height: 60px;
}

.btn-golden {
  background-color: #d1b67f;
  width: 250px;
  height: 50px;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  transition: all 0.5s ease;
}

.btn-golden:hover {
  background-color: #d1b67f;
  color: black;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 260px;
  height: 60px;
}

.shadow-golden--1 {
  box-shadow: -25px -12px 0px rgba(209, 182, 127, 1);
}

.shadow-golden-2 {
  box-shadow: 25px -12px 0px rgba(209, 182, 127, 1);
}

.shadow-golden-3 {
  box-shadow: -10px 0px 0px rgba(209, 182, 127, 1);
}

.shadow-theme-1 {
  box-shadow: -25px -12px 0px #495c6a;
}

.shadow-theme-2 {
  box-shadow: 25px -12px 0px #495c6a;
}

.shadow-theme-3 {
  box-shadow: -10px 0px 0px #495c6a;
}

.payment-card {
  border-radius: 32px 50px 0px 0px;
}

.card {
  border-radius: 0px;
}

.hero-1 {
  min-height: 84vh;
  width: 100%;
}

.hero-1-section-2-img {
  max-width: 90vh;
  height: auto;
  /* margin-right: -100px; */
  border-top-left-radius: 150px;
  border-bottom-right-radius: 150px;
}

.hero-3 {
  border: 24px solid #d1b67f;
}

.border-golden {
  border: 1px solid #d1b67f;
}

.MultiCarousel {
  float: left;
  overflow: hidden;
  padding: 15px;
  width: 100%;
  position: relative;
}

.MultiCarousel .MultiCarousel-inner {
  transition: 1s ease all;
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item {
  float: left;
}

.MultiCarousel .MultiCarousel-inner .item > div {
  text-align: center;
  padding: 10px;
  margin: 10px;
  background: #f1f1f1;
  color: #666;
}

.MultiCarousel .leftLst,
.MultiCarousel .rightLst {
  position: absolute;
  border-radius: 50%;
  top: calc(50% - 20px);
}

.MultiCarousel .leftLst {
  left: 0;
}

.MultiCarousel .rightLst {
  right: 0;
}

.MultiCarousel .leftLst.over,
.MultiCarousel .rightLst.over {
  pointer-events: none;
  background: white;
  color: black;
  border: none;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

/* Overlay */
.cta-background {
  width: 720px;
  margin-top: 10px;
  height: 457px;
  left: calc(50% - 720px / 2);
  top: 0px;
  bottom: 0px;

  background: #374957;
  border-radius: 500px 500px 0px 0px;
}
@media all and (max-width: 767px) {
  .lead-generation-close-btn {
    position: absolute;
    top: -28px;
    right: -20px;
  }
}
