.profile-section {
    background-color: #ffffff;
    padding: 40px 0;
}
.profile-image {
    border-top-right-radius: 150px; 
    object-fit: cover;
}
.fun-fact {
    background-color: #fdefdb; /* Light orange color */
    padding: 20px;
    border-radius: 0px;
    margin-top: 20px;
}