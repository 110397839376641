.fixed-button {
    z-index: 99999;
    background-color: #27333D;
    color: white;
    text-transform: uppercase;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    padding: 2.3rem 1rem 2.3rem 1.2rem;
    font-size: 1rem;
    line-height: 1;
    position: fixed;
    inset: 40% 0% auto auto;
    transform: translateY(-50%);
    max-width: 100%;
    display: inline-block;
    box-shadow: 0 0 12px #fff;
}

.fixed-button2 {
    z-index: 99999;
    background-color: #D1B67F;
    height: 50px;
    width: 200px;
    border-top-left-radius: 0px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    transition: all 0.5s ease;
    position: fixed;
    left: 10px;
    bottom: 30px;
    max-width: 100%;
    /* display: inline-block; */
    box-shadow: 0 0 12px #fff;
    gap: 10px;
}

.fixed-button2 span svg {
    width: 20px;
    height: 20px;
}

.fixed-button2:hover {
    background-color: #D1B67F;
    /* color: #27333D; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 50px;
    width: 200px;
}

.vertical-text {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

@media all and (max-width:991px) {
    .fixed-button2 {
        display: none;
    }
}